import React from 'react';
import './Common.scss';

class Navigation extends React.Component {
    constructor() {
        super()
        this.state = {
            navActive: false
        }
    }
    navSlide = () => {
        this.setState({ navActive: !this.state.navActive })
    }
    changeTheme = (e) => {
        const isChecked = e.target.checked
        if (isChecked) {
            document.documentElement.setAttribute('data-theme', 'light')
        } else {
            document.documentElement.setAttribute('data-theme', 'dark')
        }
    }
    render() {
        return (
            <nav id="home">
                <div className="logo">
                    MukeshSan
                </div>
                {/* <div className="nav-space"></div> */}
                <ul className={`nav-links ${this.state.navActive ? ' nav-active' : ''}`}>
                    <li key="1">
                        <a href='#home'>Home</a>
                    </li>
                    <li key="2">
                        <a href='#skills'>Skills</a>
                    </li>
                    <li key="3">
                        <a href='#experience'>Experience</a>
                    </li>
                    <li key="4">
                        <a href='/#'>Portfolio</a>
                    </li>
                </ul>
                <div id="theme-switch">
                    <input className="container_toggle" type="checkbox" id="switch" name="mode" onChange={(e) => this.changeTheme(e)} />
                    <label htmlFor="switch">Toggle</label>
                </div>
                <div className={`burger${this.state.navActive ? ' toggle' : ''}`} onClick={this.navSlide}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
            </nav>
        );
    }
}

export default Navigation;
