import React from 'react';
import UseScript from './global/UseScript';
import './App.css';
import Navigation from './global/Navigation'
import Banner from './components/Banner'
import About from './components/About'
// import Experience from './components/Experience'

function App() {
  UseScript(process.env.PUBLIC_URL + '/app-scripts.js');
  return (
    <div className="body-wrap">
      <Navigation />
      <div className="w-container">
        <Banner />
        <About />
        {/* <Experience /> */}
      </div>
      <div className="courser"> <span className="curson-eye"></span> </div>
      <div className="courser-lg"> <span className="curson-eye"></span> </div>
      <div className="courser-eye-line"> <span className="curson-eye"></span> </div>
    </div >
  );
}

export default App;
