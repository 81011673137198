import React from 'react';
import '../global/global.scss';

class Banner extends React.Component {
    render() {
        return (
            <section className="banner">
                <h2 className="font-cursive">Hi. </h2>
                <h2 className="font-cursive">This is </h2><br />
                <h2 className="font-high "> Mukesh <img className="my-photo" title="HTML5" alt="HTML5" src={process.env.PUBLIC_URL + '/assets/mukeshsan.jpg'} />
                </h2>
                <h2 className="font-cursive"> &amp; I'm a </h2>
                <h2 className="font-high">Creative Web Developer </h2>
                <h2 className="font-cursive">for HCL</h2>
            </section>
        );
    }
}

export default Banner;
