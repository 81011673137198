import React from 'react';
import '../global/global.scss';

class About extends React.Component {
    render() {
        return (
            <section className="skills" id="skills">
                <section>
                    <div className="skills-wrap">
                        <div className="skills-heading font-high">Frontend Skills</div>
                        <img title="HTML5" alt="HTML5" src={process.env.PUBLIC_URL + '/assets/html.png'} />
                        <img title="CSS3" alt="CSS3" src={process.env.PUBLIC_URL + '/assets/css.png'} />
                        <img title="JavaScript" alt="JavaScript" src={process.env.PUBLIC_URL + '/assets/js.png'} />
                        <img title="ReactJs" alt="ReactJs" src={process.env.PUBLIC_URL + '/assets/react.png'} />
                        <img title="Angular" alt="Angular" src={process.env.PUBLIC_URL + '/assets/angular.png'} />
                        <img title="Polymer" alt="Polymer" src={process.env.PUBLIC_URL + '/assets/polymer.png'} />
                        <img title="Webpack" alt="Webpack" src={process.env.PUBLIC_URL + '/assets/webpack.png'} />
                    </div>
                </section>
                <section>
                    <div className="skills-wrap">
                        <div className="skills-heading font-high">Backend Skills</div>
                        <img title="NodeJs/ExpressJs" alt="NodeJs/ExpressJs" src={process.env.PUBLIC_URL + '/assets/node.png'} />
                        <img title="MySql" alt="MySql" src={process.env.PUBLIC_URL + '/assets/mysql.png'} />
                        <img title="Php" alt="Php" src={process.env.PUBLIC_URL + '/assets/php.png'} />
                        <img title="PostgreSQL" alt="MySql" src={process.env.PUBLIC_URL + '/assets/postgresql.png'} />
                    </div>
                </section>
                <section>
                    <div className="skills-wrap">
                        <div className="skills-heading font-high">Tools Used</div>
                        <img title="Travis CI" alt="NodeJs/ExpressJs" src={process.env.PUBLIC_URL + '/assets/travisci.png'} />
                        <img title="New Relic - SRE" alt="New Relic - SRE" src={process.env.PUBLIC_URL + '/assets/newrelic.png'} />
                        <img title="DevOps" alt="DevOps" src={process.env.PUBLIC_URL + '/assets/DevOps.png'} />
                        <img title="Netlify" alt="Netlify" src={process.env.PUBLIC_URL + '/assets/netlify.png'} />
                    </div>
                </section>
            </section>
        );
    }
}

export default About;
